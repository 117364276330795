import type { ItemModel, DataItemModel, RecordSourceOptions } from 'o365-dataobject';
import type { DataGridControl } from 'o365-datgrid';

import { DataObject, Item as DataItem, DataHandler } from 'o365-dataobject';
import { VersionedStorage, EventEmitter } from 'o365-modules';
import { logger, Url } from 'o365-utils';

import { NodeItem, type ICalculatedField } from './DataObject.NodeItem.ts';
import { HierarchyLevelConfiguration, type NodeDataHierarchyConfigurationOptions } from './DataObject.Configurations.Hierarchy.ts';
import { GroupByLevelConfiguration, type NodeDataGroupByConfigurationOptions } from './DataObject.Configurations.GroupBy.ts'

declare module 'o365-dataobject' {
    interface DataObject<T> {
        nodeData: NodeData<T>;
        hasNodeData: boolean;
    }
}

Object.defineProperties(DataObject.prototype, {
    'nodeData': {
        get() {
            if (this._nodeData == null) {
                this._nodeData = new NodeData(this);
                this._nodeData.initialize();
            }
            return this._nodeData;
        }
    },
    'hasNodeData': {
        get() {
            return !!this._nodeData;
        }
    }
});

/**
 * Node Data (hierarchy / group by) extension for DataObject 
 */
export default class NodeData<T extends ItemModel = ItemModel> {
    private _dataObject: DataObject<T>;
    private _initialized = false;
    private _levelConfigurations: INodeDataLevelConfiguration<T>[] = [];
    private _enabled = false;
    private _root: NodeItem<T>[] = [];
    private _data: DataItemModel<T>[] = [];
    private _updated: Date = new Date();
    private _localStorage: VersionedStorage<Record<string, true>, { id: string }>;
    /** Used to restore dynamic loading to previous state when disabling this extension */
    private _hasDynamicLoading?: boolean;
    /** Calculated fields array with generator functions */
    private _calculatedFields: ICalculatedField<T>[] = [];

    /** When true will auto expand all nodes after filtering */
    autoExpandOnFilter = true;
    /**
     * When true will load all of the necessary fields for all of the rows with the current whereClause/filterString. The node
     * structure will then be constructed on client. This mode is not suited for very large datasets and is geared more towards <20k rows.
     */
    loadFullStructure = false;
    /** Current expanded level used by other controls */
    currentLevel = 0;
    /** Deepest level in the structure */
    deepestLevel = 0;
    /** Events emitter utilized by other controls for reacting to node data changes */
    events: EventEmitter<NodeDataEvents<T>>;
    /** When true setting isSelected will not update detail nodes isSelected values */
    disableDetailsMultiSelect = false;
    /** When true will not restore expanded states from local store */
    disableLocalStore = false;
    /** When set to true will disable auto new record on empty roots */
    disableAutoNewRecord = false;

    /**
     * View that will be used for grouping system properties. 
     * This view needs to have properties values table joined in onto the main view and
     * select [PropertyName] with [Value] AS 'PropertyName' in addition to the same fields as the main view.
     */
    withPropertiesView?: string;
    withPropertiesDefinitionProc?: string;

    /** Original DataObject load */
    private _dataObject_load!: DataObject<T>['load'];
    /** Original RecordSource retrieve */
    private _recordSource_retrieve!: DataObject<T>['recordSource']['retrieve'];

    private _cancelAfterDelete?: () => void;
    private _cancelChangesCancelled?: () => void;
    private _cancelAfterSave?: () => void;

    canCreateNodes = false;

    /** Date at which display data was last updated. Used by watchers */
    get updated() { return this._updated; }
    /** Indicates if the node data overrides are currently enabled */
    get enabled() { return this._enabled; }

    get localStorageKey() {
        return `${this._dataObject.appId}_${this._dataObject.id}_nodeData`
    }

    /** Root array of NodeItems */
    get root() { return this._root; }

    /** Get the current index node item */
    get current(): undefined | NodeItem<T> | DataItem<T> {
        if (this._dataObject.currentIndex == null) {
            return undefined;
        } else {
            return this._dataObject.storage.data[this._dataObject.currentIndex]?._getNode
                ? this._dataObject.storage.data[this._dataObject.currentIndex]._getNode()
                : undefined
        }
    }

    /** Node levels configurations */
    get configurations() { return this._levelConfigurations; }

    get isActive() {
        return this.configurations.length && this.configurations.every(config => !config.disabled);
    }

    get calculatedFields() { return this._calculatedFields; }

    constructor(pDataObject: DataObject<T>) {
        this._dataObject = pDataObject;
        this._localStorage = new VersionedStorage({
            baseline: { id: `${this._dataObject.id}` },
            id: this.localStorageKey,
        });
        this.events = new EventEmitter();
    }

    /** Should not be called outside of DataObject */
    initialize() {
        if (this._initialized) { return; }
        this._initialized = true;
        // Original DataObject functions
        this._dataObject_load = this._dataObject.load.bind(this._dataObject);
        this._recordSource_retrieve = this._dataObject.recordSource.retrieve.bind(this._dataObject.recordSource);
    }

    /** Enable NodeData overrides on the DataObject */
    enable() {
        if (this._enabled) { return; }

        this._enabled = true;
        this._data.splice(0, this._data.length);
        this._dataObject.setStoragePointer(this._data);

        this._dataObject.createNewAtTheEnd = true;

        this._dataObject.load = this.load.bind(this);
        this._dataObject.recordSource.retrieve = this.retrieve.bind(this);

        this._cancelAfterSave = this._dataObject.on('AfterSave', (pOptions, _i, pDataItem) => {
            if (pDataItem['_getNode']) {
                const node: NodeItem<T> = pDataItem._getNode();
                const configuration = node.getConfiguration();
                node.canCreateNodes = configuration.canCreateNodes(node);

                if (pOptions.operation === 'create' && node.key == 'undefined') {
                    if (configuration instanceof GroupByLevelConfiguration) {
                        // TODO: Key update for new group nodes
                    } else if (configuration instanceof HierarchyLevelConfiguration) {
                        if (configuration.idPathField) {
                            const keyArr = (node as any)[configuration.idPathField]?.split('/');
                            keyArr.splice(0, 1);
                            keyArr.splice(keyArr.length - 1, 1);
                            node.keyArray.splice(0, 0, ...keyArr);
                        } else if (configuration.idField) {
                            let keyArr = [`${(node as any)[configuration.idField]}`];
                            let currentParent = node.getParent();
                            if (currentParent) {
                                keyArr = [...currentParent.keyArray, ...keyArr];
                            }
                            node.keyArray.splice(0, 0, ...keyArr);
                        }
                    }
                }
            }
        });

        this._cancelAfterDelete = this._dataObject.on('AfterDelete', (_options, item) => {
            const node: NodeItem<T> | undefined = (item as any)._getNode ? (item as any)._getNode() : undefined;
            if (node) {
                const parentNode = node.getParent();
                if (parentNode) {
                    const nodeIndex = parentNode.details.findIndex(x => x.key === node.key);
                    if (nodeIndex !== -1) {
                        parentNode.details.splice(nodeIndex, 1);
                    } else {
                        logger.warn('Could not remove node, failed to find in parent', node);
                    }
                } else {
                    // Node is possibly at root, find the index and remove it
                    const nodeIndex = this._root.findIndex(x => x.key === node.key);
                    if (nodeIndex !== -1) {
                        this._root.splice(nodeIndex, 1);
                    } else {
                        logger.warn('Could not remove node, failed to find in root', node);
                    }
                }
            }
            this.update();
        });
        this._cancelChangesCancelled = this._dataObject.on('ChangesCancelled', (rows) => {
            let updateDisplayArray = false;

            for (const row of rows) {
                if (row.isNewRecord && row.isEmpty) {
                    // New record was canceled and removed from storage, remove from node data too.
                    const node: NodeItem<T> = (row as any)._getNode();
                    if (node) {
                        const parent = node.getParent();
                        if (parent) {
                            const index = parent.details.findIndex(x => x.key === node.key);
                            if (index === -1) { return; }
                            parent.details.splice(index, 1);
                            updateDisplayArray = true;
                        } else {
                            const index = this.root.findIndex(x => x.key === node.key);
                            if (index === -1) { return; }
                            this.root.splice(index, 1);
                            updateDisplayArray = true;
                        }
                    }
                }
            }

            if (updateDisplayArray) {
                this.update();
            }
        });
        if (this._dataObject.hasDynamicLoading) {
            this._hasDynamicLoading = this._dataObject.dynamicLoading.enabled;
            this._dataObject.dynamicLoading.enabled = false;
        }
    }

    /** Disable NodeData overrides on the DataObject */
    disable() {
        if (!this._enabled) { return; }

        this._enabled = false;
        this._dataObject.setStoragePointer(undefined);

        this._dataObject.load = this._dataObject_load;
        this._dataObject.recordSource.retrieve = this._recordSource_retrieve;

        if (this._cancelAfterDelete) {
            this._cancelAfterDelete();
            this._cancelAfterDelete = undefined;
        }
        if (this._cancelChangesCancelled) {
            this._cancelChangesCancelled();
            this._cancelChangesCancelled = undefined;
        }
        if (this._cancelAfterSave) {
            this._cancelAfterSave();
            this._cancelAfterSave = undefined;
        }
        if (this._hasDynamicLoading != null) {
            this._dataObject.dynamicLoading.enabled = this._hasDynamicLoading;
        }
    }

    /** Push new level of structure configuration */
    addConfiguration(pOptions: NodeDataHierarchyConfigurationOptions<T> | NodeDataGroupByConfigurationOptions<T>, pLevel?: number) {
        if (pOptions.type == null) {
            (pOptions as any).type = 'hierarchy';
            logger.warn(`${this._dataObject.id}.nodeData.addConfiguration: Provided configuration has no type. Should be either 'hierarchy' or 'groupBy'`);
        }
        let config: INodeDataLevelConfiguration<T> | null = null
        if (pOptions.type === 'hierarchy') {
            config = new HierarchyLevelConfiguration(this._dataObject, pOptions, (pLevel) => {
                return this.configurations[pLevel];
            });
        } else {
            config = new GroupByLevelConfiguration(this._dataObject, pOptions, (pLevel) => {
                return this.configurations[pLevel];
            });
        }
        if (pLevel == null && this._levelConfigurations.at(-1)?.type === 'hierarchy') {
            pLevel = this._levelConfigurations.length - 1;
        }
        if (pLevel != null && pLevel < this.configurations.length) {
            this._levelConfigurations.splice(pLevel, 0, config!);
        } else {
            this._levelConfigurations.push(config!);
        }
        this.configurations.forEach((configuration, index) => configuration.level = index);
        this.events.emit('ConfigurationAdded');
    }

    /** Remove a configuration level */
    removeConfiguration(pLevel: number) {
        if (this._levelConfigurations.at(pLevel) != null) {
            this._levelConfigurations.splice(pLevel, 1);
        }
        this.configurations.forEach((configuration, index) => configuration.level = index);
        this.events.emit('ConfigurationRemoved');
    }

    /** Remove all configuration levels */
    removeAllConfigurations() {
        if (this._levelConfigurations.length > 0) {
            this._levelConfigurations.splice(0, this._levelConfigurations.length);
        }
        this.events.emit('ConfigurationRemoved');
    }

    /** Change configuration level */
    moveConfiguration(pFromLevel: number, pToLevel: number) {
        const config = this.configurations.splice(pFromLevel, 1)[0];
        this.configurations.splice(pToLevel, 0, config);
        this.configurations.forEach((configuration, index) => configuration.level = index);
    }

    /** TODO: Remove and encourage the usage of dataObject.load */
    async init() {
        if (this._levelConfigurations.length === 0) { return; }
        const config = this._levelConfigurations[0];
        const expandedKeys = this.disableLocalStore
            ? undefined
            : this._localStorage.getStoredValue() ?? undefined;
        this._dataObject.state.isLoading = true;
        this._root = await config.getStructure({
            expandedKeys: expandedKeys,
            autoExpandOnFilter: this.autoExpandOnFilter
        });
        if (this._dataObject.recordSource.filterString) {
            this._root = await config.getStructure({
                expandedKeys: expandedKeys
            });
        }

        this._dataObject.state.isLoading = false;
        if (this._dataObject.selectFirstRowOnLoad) {
            if (this.root[0]?.isLoading) {
                this.root[0].loadingPromise?.then(() => {
                    const index = this.root[0].dataItem?.index;
                    if (index != null) {
                        this._dataObject.setCurrentIndex(index, true);
                    }
                });
            }
        }

        if (!this.disableAutoNewRecord && this._dataObject.allowInsert && this.root.length === 0) {
            const item = this._dataObject.storage.data.find(x => x.isNewRecord);
            const node = config.createNode(item ?? {});
            this.root.push(node);
        }

        this.updateRowCount();

        this.update();
        this._dataObject.emit('DataLoaded', this._dataObject.data, {});
    }

    /** Create new item and push it to root */
    createNew(pItem: any) {
        if (!this.enabled) {
            return this._dataObject.createNew(pItem);
        }
        const config = this.configurations[0];
        const node = config?.createNode({ item: pItem ?? {} }, true);
        this.root.push(node);
        this._dataObject.setCurrentIndex(node.index!);
        this.update();
        return node;
    }

    /** Expand all nodes that have details in the entire structure */
    expandAll() {
        const traverseExpand = (node: NodeItem<T>) => {
            if (node.hasNodes && !node.expanded) {
                node.expanded = true;
            }
            for (const detail of node.details) {
                traverseExpand(detail);
            }
        };
        for (const node of this._root) {
            traverseExpand(node);
        }
        this.update();
    }

    /** Expand all nodes that have details and have currently expanded parents */
    expandAllVisible() {
        const traverseExpand = (node: NodeItem<T>) => {
            if (node.hasNodes && !node.expanded) {
                node.expanded = true;
            } else {
                for (const detail of node.details) {
                    traverseExpand(detail);
                }
            }
        };
        for (const node of this._root) {
            traverseExpand(node);
        }
        this.update();
    }

    /** Collapse all nodes that have details in the entire structure */
    collapseAll() {
        const traverseCollapse = (node: NodeItem<T>) => {
            if (node.expanded) {
                node.expanded = false;
            }
            for (const detail of node.details) {
                traverseCollapse(detail);
            }
        };
        for (const node of this._root) {
            traverseCollapse(node);
        }
        this.update();
    }

    /** Collapse all nodes that have details and have currently expanded parents */
    collapseAllVisible() {
        const traverseCollapse = (node: NodeItem<T>) => {
            if (node.expanded) {
                node.expanded = false;
            } else {
                for (const detail of node.details) {
                    traverseCollapse(detail);
                }
            }
        };
        for (const node of this._root) {
            traverseCollapse(node);
        }
        this.update();
    }

    /** 
     * Expand/collapse rows to a given level
     */
    expandToLevel(pLevel: number) {
        if (pLevel > this.deepestLevel) { pLevel = this.deepestLevel; }
        if (pLevel < 0) { pLevel = 0; }
        const traverseExpand = (node: NodeItem<T>) => {
            if (node.hasNodes) {
                node.expanded = node.level < pLevel;
            }
            for (const detail of node.details) {
                traverseExpand(detail);
            }
        };
        for (const node of this._root) {
            traverseExpand(node);
        }
        this.currentLevel = pLevel;
        this.update();
    }

    /** Flatten out expanded nodes from root into an array */
    getFlatStructure() {
        const result: (DataItemModel<T> | undefined)[] = [];
        let index = 0;
        const pushDetails = (node: NodeItem<T>) => {
            node.resetCount();
            node.displayIndex = index++;
            // @ts-ignore
            result.push(node);
            if (node.expanded) {
                for (const detail of node.details) {
                    pushDetails(detail);
                }
            }
        };
        for (const node of this._root) {
            pushDetails(node);
        }
        return result;
    }

    /** Update the DataObject row count with the loaded structure items count */
    updateRowCount() {
        if (!this._enabled) { return; }
        let rowCount = 0;
        for (const item of this._root) {
            rowCount += item.count + 1;
        }
        this._dataObject.state.rowCount = rowCount;
    }

    addNode(pItem: DataItemModel<T>, pOptions?: {
        /** If provided will push to this node's details. When none is provided will push to root */
        parentNode?: NodeItem<T>,
        /** If provided will push right after this node. When none provided will push to the end of parent/root */
        siblingNode?: NodeItem<T>
    }) {
        if (!this._enabled) { return; }

        let config: INodeDataLevelConfiguration<T> | null = null;
        if (pOptions?.siblingNode) {
            config = pOptions.siblingNode.getConfiguration();
        } else if (pOptions?.parentNode) {
            config = pOptions.parentNode.getConfiguration();
        } else {
            config = this._levelConfigurations.at(0) || null;
        }
        if (config == null) {
            logger.warn('Could not get configuration for creating new node')
            return;
        }

        const node = config.createNode({
            item: pItem
        });
        if (pOptions?.siblingNode) {
            const parent = pOptions.siblingNode.getParent();
            if (parent) {
                const siblingIndex = parent.details.findIndex(x => x.key === pOptions.siblingNode!.key);
                if (siblingIndex == -1) {
                    logger.warn('Failed to append node due to malformed structure. Provided sibling node is not found in its parent node');
                    return;
                }
                node.level = parent.level + 1;
                parent.details.splice(siblingIndex + 1, 0, node);;
                node.getParent = () => parent;
            } else {
                const siblingIndex = this._root.findIndex(x => x.key === pOptions.siblingNode!.key);
                if (siblingIndex == -1) {
                    logger.warn('Failed to append node due to malformed structure. Provided sibling node is not found in root');
                    return;
                }
                node.level = 0;
                this._root.splice(siblingIndex + 1, 0, node);;
            }
        } else if (pOptions?.parentNode) {
            node.level = pOptions.parentNode.level + 1;
            pOptions.parentNode.details.push(node);
            node.getParent = () => pOptions.parentNode;
        } else {
            node.level = 0;
            this._root.push(node);
        }
        this.update();
        this.events.emit('NodeAdded', node);
        return node;
    }

    /** Update the displayed data with flattened node items */
    update() {
        this._data.splice(0, this._data.length, ...this.getFlatStructure() as DataItemModel<T>[]);
        this._saveExpandedState();
        this._updated = new Date();
    }

    /**
     * Get data required for constructing the node structure based on current configuration
     * Only used when loadFullStructure is enabled
     */
    async getData() {
        const fieldsToLoad = new Set<string>();
        for (const configuration of this.configurations) {
            const fields = configuration.getRequiredFields();
            for (const field of fields) {
                fieldsToLoad.add(field);
            }
        }
        for (const calculatedField of this.calculatedFields) {
            for (const dependantField of calculatedField.dependantFields) {
                fieldsToLoad.add(dependantField);
            }
        }
        const selectedFields: { name: string }[] = [];
        const propertyNames: string[] = [];
        for (const field of fieldsToLoad) {
            if (field.startsWith('Property.')) {
                propertyNames.push(field.split('.')[1]);
            } else {
                selectedFields.push({ name: field });
            }
        }
        const options = this._dataObject.recordSource.getOptions();
        options.skip = 0;
        options.maxRecords = -1;
        options.fields = selectedFields;
        this._dataObject.recordSource.appendSortByFields(options.fields);
        const data = await this._recordSource_retrieve(options);
        if (propertyNames.length) {
            const propertiesOptions = this._dataObject.recordSource.getOptions();
            if (this.withPropertiesView) {
                propertiesOptions.viewName = this.withPropertiesView;
            }
            if (this.withPropertiesDefinitionProc) {
                propertiesOptions.definitionProc = this.withPropertiesDefinitionProc;
            }
            const properties = propertyNames.map(p => `'${p}'`).join(', ');
            const clause = `[PropertyName] IN (${properties})`;
            if (propertiesOptions.whereClause) {
                propertiesOptions.whereClause += ` AND ${clause}`;
            } else {
                propertiesOptions.whereClause = clause;
            }
            const idField = this._dataObject.propertiesData!.itemIdField;
            propertiesOptions.fields = [
                { name: idField },
                { name: 'PropertyName' },
                { name: 'PropertyValue' }
            ]
            propertiesOptions.maxRecords = -1;
            const propertiesData = await this._dataObject.recordSource.retrieve(propertiesOptions);
            for (const property of propertiesData) {
                const item = data.find(item => item[idField] == property[idField]);
                item[`Property.${property.PropertyName}`] = property.PropertyValue;
            }
        }
        return data;
    }

    /**
     * Find NodeItem in the loaded structure with the provided PrimKey
     */
    findNodeByPrimKey(pPrimKey: string) {
        const traverseFind = (nodeArray: NodeItem<T>[]): NodeItem<T> | null => {
            for (const node of nodeArray) {
                if (node.primKey == pPrimKey) {
                    return node;
                }

                if (node.hasNodes) {
                    const foundNode: NodeItem<T> | null = traverseFind(node.details);
                    if (foundNode) {
                        return foundNode;
                    }
                }
            }
            return null;
        };
        return traverseFind(this._dataObject.nodeData.root);
    }

    /**
     * Find NodeItem in the loaded structure with the provided PrimKey
     */
    findNodeByFetchKey(pKey: string) {
        const traverseFind = (nodeArray: NodeItem<T>[]): NodeItem<T> | null => {
            for (const node of nodeArray) {
                if (node.fetchKey == pKey) {
                    return node;
                }

                if (node.hasNodes) {
                    const foundNode: NodeItem<T> | null = traverseFind(node.details);
                    if (foundNode) {
                        return foundNode;
                    }
                }
            }
            return null;
        };
        return traverseFind(this._dataObject.nodeData.root);
    }

    enableUrl() {
        const fetchKey = Url.getParam(`nd_${this._dataObject.id}`);
        const setupUrl = () => {
            if (fetchKey) {
                const node = this.findNodeByFetchKey(fetchKey);
                if (node) {
                    this._dataObject.setCurrentIndex(node.index!);
                    node.expandTo();
                }
            }
            this._dataObject.on('CurrentIndexChanged', () => {
                if (this._dataObject.nodeData.current instanceof NodeItem) {
                    Url.setParam(`nd_${this._dataObject.id}`, this._dataObject.nodeData.current.fetchKey);
                } else {
                    Url.setParam(`nd_${this._dataObject.id}`, '');
                }
            });
        }
        if (fetchKey) {
            if (this._dataObject.state.isLoaded) {
                setupUrl();
            } else {
                this._dataObject.once('DataLoaded', () => {
                    setupUrl();
                });
            }
        } else {
            setupUrl();
        }
    }

    // --- DataObject overrides ---
    /**
     * DataObject.load override
     * @ignore
     */
    async load(...[pOptions]: Parameters<DataObject<T>['load']>): ReturnType<DataObject<T>['load']> {
        if (this._dataObject.state.isLoading) { return; }
        if (this._levelConfigurations.length == 0 || this._levelConfigurations.every(config => config.disabled)) {
            this.disable();
            return this._dataObject_load(pOptions);
        }
        this._dataObject.state.rowCount = null;
        const expandedKeys = this.disableLocalStore
            ? undefined
            : this._localStorage.getStoredValue() ?? undefined;
        if (this.loadFullStructure) {
            // Load all of the necessary data and create the full node structure on client
            this._dataObject.state.isLoading = true;
            const data = await this.getData();
            if (data.length > 20000) {
                logger.warn(`${this._dataObject.id}.nodeData: full structure load returned more than 20 000 rows. Switching to server side structure construction`);
                this.loadFullStructure = false;
                this._dataObject.state.isLoading = false;
                return this.load(pOptions);
            }
            let maxDepth = 0;
            const setupLevel = (pLevel: number, pNode: NodeItem<T> | null, pData: T[]) => {
                const configuration = this._levelConfigurations[pLevel];
                if (configuration == null || configuration.disabled) { return []; }
                const configurationOutput = configuration.getNodes(pData, {
                    startingLevel: pNode ? pNode.level + 1 : 0,
                    expandedKeys: expandedKeys,
                    autoExpandOnFilter: this.autoExpandOnFilter && !!this._dataObject.recordSource.filterString
                });
                if (configurationOutput.depth > maxDepth) { maxDepth = configurationOutput.depth; }
                const entries = configurationOutput.boundry;
                if (pNode) {
                    for (const node of configurationOutput.root) {
                        pNode.details.push(node);
                        node.getParent = () => pNode;
                    }
                }
                for (const entry of entries) {
                    const [node, subData] = entry;
                    if (pLevel < this._levelConfigurations.length - 1) {
                        setupLevel(pLevel + 1, node, subData);
                    }
                }
                return configurationOutput.root;
            }
            this._root = setupLevel(0, null, data);

            this.deepestLevel = maxDepth;
        } else {
            // Load first level and fetch others on demand. Server side group by.
            const rootConfig = this._levelConfigurations.at(0);
            if (rootConfig == null) {
                throw new TypeError(`Something went wrong. ${this._dataObject.id}.nodeData has configurations but not at index 0`);
            }
            this._dataObject.state.isLoading = true;
            this._root = await rootConfig.getStructure({
                expandedKeys: expandedKeys,
                autoExpandOnFilter: this.autoExpandOnFilter
            });
        }

        this.updateRowCount();

        if (this.configurations.length === 1 && this.configurations[0] instanceof HierarchyLevelConfiguration) {
            // Create new row if allowInsert is true, no data was loaded and the only configuration used is hierarchy
            if (!this.disableAutoNewRecord && this._dataObject.allowInsert && this.root.length === 0) {
                const item = this._dataObject.storage.data.find(x => x.isNewRecord);
                const node = this._levelConfigurations.at(0)?.createNode(item ?? {});
                if (node) {
                    this.root.push(node);
                }
            }
        } else if (!this.loadFullStructure && this.configurations.every(config => config instanceof GroupByLevelConfiguration)) {
            this.deepestLevel = this.configurations.length;
        }
        this.update();
        this._dataObject.state.isLoading = false;
        if (!this._dataObject.state.isLoaded) {
            this._dataObject.state.isLoaded = true;
        }

        let setCurrentIndexPromise: Promise<any> | undefined = undefined;
        if (this._dataObject.selectFirstRowOnLoad) {
            if (this.root[0]?.isLoading) {
                setCurrentIndexPromise = this.root[0].loadingPromise?.then(() => {
                    const index = this.root[0].dataItem?.index;
                    if (index != null) {
                        this._dataObject.setCurrentIndex(index, true);
                    }
                });
            }
        }
        const options = this._dataObject.recordSource.getOptions();
        if (setCurrentIndexPromise) {
            await setCurrentIndexPromise;
        }

        this._dataObject.emit('DataLoaded', this._dataObject.data, options);

        this.reassignParents(); 

        return this._data;
    }
    /**
     * DataObject.recordSource.retrieve override
     * @ignore
     */
    async retrieve(pOptions: Partial<RecordSourceOptions>) {
        let options = this._dataObject.recordSource.getOptions();

        if (pOptions) {
            pOptions = { ...options, ...pOptions }
        }
        const removeFilterString = this.configurations.some(x => {
            if (x instanceof HierarchyLevelConfiguration) {
                return x.requireParents ?? false;
            }
            return false;
        });
        if (removeFilterString) {
            delete pOptions.filterString;
        }

        let data: T[];
        if (this._dataObject.dataHandler instanceof DataHandler && !this._dataObject.clientSideFiltering) {
            data = await this._dataObject.dataHandler.request('retrieve', pOptions);
        } else {
            data = await this._dataObject.dataHandler.retrieve(pOptions);
        }
        return data;
    }

    // ----------------------------

    /** Set calculated fields options for NodeItem instances of this DataObject */
    setCalculatedFields(pFields: ICalculatedField<T>[]) {
        this._calculatedFields = pFields;
    }

    /**
     * Helper method for programmatically setting group by on a data object
     * This method will add group by configuraionts for the provided fields
     */
    setGroupBy(pGroupBy: (Field<T> | Field<T>[])[], pOptions?: {
        dataGridControl?: DataGridControl
    }) {
        if (this.configurations.length) {
            this.configurations.splice(0, this.configurations.length);
        }
        const getOptions = (pGroup: typeof pGroupBy[0]) => {
            const options: NodeDataGroupByConfigurationOptions<T> = {
                type: 'groupBy'
            };

            if (Array.isArray(pGroup) && pGroup.length > 1) {
                options.fields = pGroup;
            } else {
                options.fieldName = Array.isArray(pGroup) ? pGroup[0] : pGroup;
            }

            if (pOptions?.dataGridControl && options.fieldName) {
                const pathColumn = pOptions.dataGridControl.dataColumns.columns.find(col => col.field === options.fieldName && col.groupPathField);
                if (pathColumn) {
                    options.pathField = pathColumn.groupPathField;
                    options.pathIdReplace = pathColumn.groupPathReplacePlaceholder;
                    options.pathMode = pathColumn.groupPathMode;
                }
            }

            return options;
        };

        for (const group of pGroupBy) {
            this.addConfiguration(getOptions(group));
        }

        if (this.configurations.length > 0) {
            this.enable();
        } else {
            this.disable();
        }

        this.events.emit('SetGroupBy');
    }

    clearStoredState() {
        const expandedKeys: Record<string, true> = {};
        this._localStorage.storeValue(expandedKeys);
    }

    private _saveExpandedState() {
        if (this.disableLocalStore) { return; }
        const expandedKeys: Record<string, true> = {};
        const expandedNodes = ((this._data as unknown) as NodeItem<T>[]).filter(node => node.expanded);
        for (const node of expandedNodes) {
            expandedKeys[node.key] = true;
        }
        this._localStorage.storeValue(expandedKeys);
    }

    loadAllRows() {
        const promises: Promise<void>[] = [];
        const traverse = (node: NodeItem<T>) => {
            if (node.isLoading && node.loadingPromise) {
                promises.push(node.loadingPromise);
            }
            if (node.details?.length) {
                for (const detail of node.details) {
                    traverse(detail);
                }
            }
        }
        for (const node of this.root) {
            traverse(node);
        }
        return Promise.all(promises);
    }

    /** Reassign paretns to get proxies working */
    reassignParents() {
        const traverse = (pDetails: NodeItem<T>[], pParent?: NodeItem<T>) => {
            for (const node of pDetails) {
                if (pParent) { node.getParent = () => pParent; }

                if (node.details?.length) {
                    traverse(node.details, node);
                }
            }
        };
        traverse(this.root);
    }
}

export interface INodeDataLevelConfiguration<T extends ItemModel = ItemModel> {
    ui: {
        title: string,
        type: string,
    }
    level: number;
    key: string;
    type: 'groupBy' | 'hierarchy';

    disabled: boolean;
    getStructure(pOptions: NodeDataStructureOptions, pNode?: NodeItem<T>): Promise<NodeItem<T>[]>;
    createNode(pOptions: NewNodeOptions<T>, pSkipReset?: boolean): NodeItem<T>;
    canCreateNodes(pNode: NodeItem<T>): boolean;
    updateNodeParent(pNode: NodeItem<T>, pParent?: NodeItem<T>, pParentId?: string | number): void;
    getConfigurationForLevel: (pLevel: number) => INodeDataLevelConfiguration<T> | undefined;
    /**
     * Get required configuration fields
     * @v2
     */
    getRequiredFields: () => string[];
    /**
     * @param pData - already filtered data
     * @v2
     */
    getNodes: (pData: T[], pOtions: NodeDataStructureOptions) => {
        root: NodeItem<T>[],
        boundry: [NodeItem<T>, T[]][],
        depth: number
    };
    /**
     * Get placeholder node
     * @v2
     */
    getPlaceholder: (pNode: NodeItem<T>, pOptions: NodeDataStructureOptions) => NodeItem<T>;
    /** When true will expand added nodes by default */
    expandByDefault: boolean;
}


/** Options for constructing group and tree structures  */
export type NodeDataStructureOptions = {
    /** Map of keys that should be expanded by default */
    expandedKeys?: Record<string, boolean>
    /** Auto expand nodes when filtering */
    autoExpandOnFilter?: boolean;
    /** Used to determine the staring level */
    startingLevel?: number;
}

export type NewNodeOptions<T extends ItemModel = ItemModel> = {
    item?: T | DataItemModel<T>
}

type NodeDataEvents<T extends ItemModel = ItemModel> = {
    'NodeAdded': (node: NodeItem<T>) => void,
    'ExpandedToNode': (node: NodeItem<T>) => void,
    'SetGroupBy': () => void,
    'AfterIndent': (pNode: NodeItem<T>) => void,
    'AfterOutdent': (pNode: NodeItem<T>) => void,
    'ConfigurationAdded': () => void,
    'ConfigurationRemoved': () => void,
};

type Field<T> = keyof T & string;
